.card_flex {
  margin-left: 2%;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  gap: 3.75%;
}

.card {
  width: 30%;
  padding: 1%;
}

.arrow {
  position: absolute;
  z-index: -5;
}

.top_to_bottom {
  top: 35%;
  left: 36.5%;
}

.top_to_left {
  top: 18%;
  left: 18%;
  transform: rotate(60deg);
}

.top_to_right {
  top: 16%;
  left: 47%;
  transform: rotate(-60deg);
}

.transparent {
  opacity: 0;
  height: 0;
}

.cardflex {
  margin-left: 2%;
  margin-top: 18%;
  display: flex;
  flex-direction: row;
  gap: 3.75%;
  transition: 1s all;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
}

/* .card:hover {
  box-shadow: 0px 10px 20px rgba(255, 0, 0, 0.2);
  transform: translateY(-5px);
} */

@media (max-width: 500px) {
  .card_flex {
    flex-direction: column;
  }

  .cardflex {
    flex-direction: column;
  }

  .card {
    width: 98%;
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: #1e1e1e;
  color: #fff;
  overflow: hidden;
}

.error-text {
  text-align: center;
  margin-bottom: 2rem;
}

.error-text h1 {
  font-size: 7rem;
  margin-bottom: 1rem;
}

.error-text p {
  font-size: 2rem;
}


